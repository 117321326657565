import React, { Component } from 'react';

import { Form, Select, Alert } from 'library';
import { ajax_wrapper } from 'functions';


export default class CampaignSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            campaign: this.props.campaign,
            loaded: true,
            saved: false,
        };

        this.save_campaign_settings = this.save_campaign_settings.bind(this);
        this.save_callback = this.save_callback.bind(this);
    }

    save_campaign_settings(data, callback) {
        data['campaign_id'] = this.props.campaign_id;
        data['location_id'] = this.props.location_id;
        ajax_wrapper(
            'POST',
            '/api/save_campaign_location_settings/',
            data,
            this.save_callback,
        );

        callback();
    };

    save_callback = (data) => {
        this.setState({ saved: true });
    };

    render() {
        let saved = null;
        if (this.state.saved) {
            saved = <Alert type="success">Saved</Alert>;
        }

        let defaults = {'show_players': false, 'show_wiki': false}
        if (this.props.campaign_location_settings) {
            console.log("Im in here!!")
            defaults = this.props.campaign_location_settings;
        }

        return (
            <div>
                <Form submit={this.save_campaign_settings} defaults={defaults}>
                    <Select
                        options={[
                            {
                                text: 'Show Location To My Players',
                                value: true,
                            },
                            {
                                text: 'Hide Location From My Players',
                                value: false,
                            },
                        ]}
                        label="Show Location To Players"
                        name="show_players"
                    ></Select>
                    <Select
                        options={[
                            {
                                text: 'Show The Wiki and Public Notes With My Players',
                                value: true,
                            },
                            {
                                text: 'Only Show Public Notes To My Players',
                                value: false,
                            },
                        ]}
                        label="Show Wiki"
                        name="show_wiki"
                    ></Select>
                    <br />
                </Form>
                {saved}
            </div>
        );
    }
}
