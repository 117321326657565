import React, { Component } from 'react';

import { Group } from 'react-konva';

import { TextLabel, Dot, Geojson } from 'components';
import { geojson_has_points, get_position_on_map } from 'functions';
import { HEADER_HEIGHT } from 'constants';

export default class PointOfInterest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image_size: [0, 0],
        };

        this.on_click = this.on_click.bind(this);
        this.handle_drag_end = this.handle_drag_end.bind(this);
    }

    on_click(event) {
        this.props.onClick(event, this.props.data);
    }

    handle_drag_end(event) {
        let new_position = [
            event.currentTarget['attrs']['x'],
            event.currentTarget['attrs']['y'],
        ];

        if (this.props.handle_point_of_interest_drag_end) {
            this.props.handle_point_of_interest_drag_end(event, new_position);
        }
    }

    render() {
        let data = this.props.data;
        let text_size = data['scale'];

        /* let pin = (
            <Pin
                width={this.props.map_width / 20}
                image_size={this.state.image_size}
                highlighted={this.props.highlighted}
                on_load={(img) =>
                    this.setState({
                        image_size: [img.width, img.height],
                    })
                }
            />
        ); */

        let shape_representation = null;
        if (
            geojson_has_points(data['geojson']) &&
            ['LineString'].includes(data['geojson']['geometry']['type'])
        ) {
            shape_representation = [<Geojson {...data['geojson']} />];
            if (this.props.editing_shape) {
                let points = data['geojson']['geometry']['coordinates'];
                for (let i = 0; i < points.length; i += 2) {
                    shape_representation.push(
                        <Dot
                            index={i}
                            x={points[i]}
                            y={points[i + 1]}
                            size={3}
                            draggable={true}
                            onDragEnd={this.props.handle_shape_point_drag_end}
                        />,
                    );
                }
            }
        } else {
            if (data['display_dot']) {
                let dot_size = data['scale'] / 3;
                shape_representation = (
                    <Dot size={dot_size} highlighted={this.props.highlighted} />
                );
            }
        }

        return (
            <Group
                key={this.props.data['id']}
                style={{ cursor: 'pointer' }}
                x={this.props.data['lat']}
                y={this.props.data['lng']}
                onClick={this.on_click}
                onTap={this.on_click}
                draggable={
                    this.props.handle_point_of_interest_drag_end &&
                    this.props.highlighted &&
                    !this.props.shift &&
                    !this.props.editing_shape
                }
                onDragEnd={this.handle_drag_end}
            >
                {shape_representation}
                <TextLabel
                    size={text_size}
                    label_x_offset={data['label_x_offset']}
                    label_y_offset={data['label_y_offset']}
                    label_wrap_ratio={data['label_wrap_ratio']}
                    rotation={data['rotation']}
                    highlighted={this.props.highlighted}
                    alignment="top"
                    text={data['name']}
                    draggable={
                        this.props.handle_text_label_drag_end &&
                        this.props.highlighted &&
                        this.props.shift
                    }
                    onDragEnd={this.props.handle_text_label_drag_end}
                />
            </Group>
        );
    }
}
