import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container } from 'library';
import {
    ajax_wrapper,
    get_global_state,
    get_position_on_map,
    geojson_has_points,
} from 'functions';
import {
    CanvasMap,
    MapSidebar,
    CreatorCredit,
    PointOfInterest,
    PointOfInterestDetails,
    Search,
    LocationsLoading,
} from 'components';

import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

const PAGE_SIZE = 200;
export default class MapViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: this.props.location ? true : false,
            children_loaded: this.props.location ? true : false,
            zoom: 0.5,
            position: [0, 0],
            location: this.props.location || {
                children: [],
                campaign: {},
            },
            next_page: null,

            map_image_loaded: false,
            map_image_size: [0, 0],
            selected_point_of_interest: null,
            move_pin: false,
        };

        this.calculate_size = this.calculate_size.bind(this);
        this.handle_map_image_load = this.handle_map_image_load.bind(this);
        this.select_poi = this.select_poi.bind(this);
    }

    componentDidMount() {
        if (!this.state.location['id']) {
            // Get location
            this.get_location();
        }

        // Set initial size
        this.calculate_size();
        // Update size
        window.addEventListener('resize', this.calculate_size);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.location &&
            this.props.location.children !== this.state.location.children
        ) {
            let location = this.state.location;
            location.children = this.props.location.children;

            this.setState({
                location: location,
            });
        }
    }

    get_location = () => {
        let params = get_global_state()['params'];

        let url = '/api/location_map_details/sword_coast/';
        if ('name' in params) {
            url = `/api/location_map_details/${params['name']}/`;
        }

        ajax_wrapper(
            'GET',
            url,
            { campaign_id: params['campaign_id'] },
            (value) => this.load_location(value),
        );
    };

    load_location = (value) => {
        let new_state = {
            loaded: true,
        };

        new_state['location'] = Object.assign({}, this.state.location, value);

        if (!isMobile && !this.state.loaded) {
            new_state['selected_point_of_interest'] = new_state['location'];
        }

        this.setState(new_state, this.get_children);
    };

    get_children = (page) => {
        let params = get_global_state()['params'];

        let url = '/api/location_children/sword_coast/';
        if ('name' in params) {
            url = `/api/location_children/${params['name']}/`;
        }

        if (page) {
            url += `?page=${page}`;
        }

        ajax_wrapper('GET', url, {}, (value) => this.load_children(value));
    };

    load_children = (value) => {
        let new_state = {
            location: this.state.location,
            next_page: value['next_page'],
        };

        if (!new_state['next_page']) {
            new_state['children_loaded'] = true;
        }

        // Prevent duplicated requests from extending too much
        if (
            (value['next_page'] &&
                new_state['location']['children'].length >
                    value['next_page'] * PAGE_SIZE) ||
            (!value['next_page'] &&
                new_state['location']['children'].length ==
                    value['children'].length)
        ) {
            return false;
        }

        for (let child of value['children']) {
            if (!child.wiki_page_content) {
                continue;
            }
            for (let section of child.wiki_page_content) {
                let section_text = '';
                for (let subsection of section.subsections) {
                    for (let item of subsection.content) {
                        section_text += item;
                    }
                }

                let clean_text = section_text.replaceAll(/<br>/g, '\n');
                clean_text = clean_text.replaceAll(/<br\/>/g, '\n');
                clean_text = clean_text.replaceAll(/<[^>]+(>|$)/g, '');
                clean_text = clean_text.replaceAll(/\[[^\]]+(\]|$)/g, '');
                clean_text = clean_text.replaceAll(/\t/g, '');
                clean_text = clean_text.replaceAll(/(\n)\1{1,}/g, '\n');
                clean_text = clean_text.replaceAll(/\n/g, '<br>');

                section['clean_text'] = clean_text;
            }
        }

        new_state['location']['children'].push(...value['children']);

        this.setState(new_state, () => {
            if (this.state.next_page) {
                this.get_children(this.state.next_page);
            }
        });
    };

    calculate_size() {
        this.setState({
            width: $(window).width(),
            height: $(window).height() - HEADER_HEIGHT,
        });
    }

    handle_map_image_load(img, zoom, position) {
        this.setState({
            map_image_loaded: true,
            map_image_size: [img.width, img.height],
            zoom: zoom,
            position: position,
        });
    }

    select_poi(event, data) {
        if (this.props.handle_point_of_interest_click) {
            this.props.handle_point_of_interest_click(event, data);
        } else {
            if (event) {
                event.cancelBubble = true;
            }
            this.setState({
                selected_point_of_interest: data,
            });
        }
    }

    pan_to_location = (location) => {
        console.log('Pan to location', location);

        let zoom = 40 / location.scale;
        let lat = -location.lat * zoom + this.props.width / 2;
        let lng = -location.lng * zoom + this.props.height / 2;
        this.setState({
            zoom: zoom,
            position: [lat, lng],
            selected_point_of_interest: location,
            pan_location: location,
        });
    };

    handle_map_click = (event, position) => {
        if (this.state.selected_point_of_interest) {
            this.select_poi(null, null);
        } else {
            this.select_poi(event, this.state.location);
        }
    };

    render() {
        let bounding_box = [
            [0, 0],
            [0, 0],
        ];
        bounding_box[0] = get_position_on_map(
            [0, HEADER_HEIGHT],
            this.state.position,
            this.state.zoom,
        );
        bounding_box[1] = get_position_on_map(
            [this.state.width, this.state.height + HEADER_HEIGHT],
            this.state.position,
            this.state.zoom,
        );

        let selected_point_of_interest =
            this.props.selected_point_of_interest ||
            this.state.selected_point_of_interest;
        let shapes_of_interest = [];
        let points_of_interest = [];

        let dm = true;
        let campaign_notes = {};
        let campaign_location_settings = {};
        let campaign = {};
        let campaign_settings = {};
        if (this.state.location.campaign['id']) {
            campaign = this.state.location.campaign;
            let user = window.secret_react_state.user;
            if (user) {
                dm = user.id == campaign.owner;
            }
            else {
                dm = false;
            }

            if (this.state.selected_point_of_interest) {
                let lookup_id =
                    this.state.selected_point_of_interest.location_id;

                let notes = this.state.location.campaign_notes;
                let campaign_settings = this.state.location.campaign_settings;

                campaign_notes =
                    notes && lookup_id in notes ? notes[lookup_id] : [];
                campaign_location_settings =
                campaign_settings && lookup_id in campaign_settings
                        ? campaign_settings[lookup_id]
                        : {};
            }
        }

        if (this.state.map_image_loaded) {
            campaign_settings = this.state.location.campaign_settings; //not sure why I have to set this here again, but it was coming back as undefined otherwise. Even though it should be set above.
            
            for (let item of this.state.location.children) {
                if (campaign['id'] && !dm && campaign_settings) {
                    
                    if (
                        !campaign_settings[item['location_id']] ||
                        campaign_settings[item['location_id']][
                            'show_players'
                        ] == false
                    ) {
                        continue;
                    }
                }

                let always_show = false;
                if (geojson_has_points(item['geojson'])) {
                    always_show = true;
                }
                if (
                    selected_point_of_interest &&
                    item['id'] === selected_point_of_interest['id']
                ) {
                    always_show = true;
                }

                if (
                    !always_show &&
                    (item['lat'] < bounding_box[0][0] ||
                        item['lat'] > bounding_box[1][0] ||
                        item['lng'] < bounding_box[0][1] ||
                        item['lng'] > bounding_box[1][1])
                ) {
                    continue;
                }

                if (
                    !always_show &&
                    12 / this.state.zoom > item['scale'] * 1.5
                ) {
                    continue;
                }

                /*
                if (item.lat === 0 && item.lng === 0) {
                    continue;
                }
                */

                let poi = (
                    <PointOfInterest
                        key={item['id']}
                        map_width={this.state.map_image_size[0]}
                        data={item}
                        zoom={this.state.zoom}
                        position={this.state.position}
                        onClick={
                            this.props.handle_point_of_interest_click ||
                            function (event, data) {
                                event.cancelBubble = true;
                                this.setState({
                                    selected_point_of_interest: data,
                                });
                            }.bind(this)
                        }
                        highlighted={
                            selected_point_of_interest &&
                            item['id'] === selected_point_of_interest['id']
                        }
                        shift={this.props.shift}
                        // Optional event listeners passthrough
                        handle_point_of_interest_drag_end={
                            this.props.handle_point_of_interest_drag_end
                        }
                        handle_text_label_drag_end={
                            this.props.handle_text_label_drag_end
                        }
                        editing_shape={this.props.editing_shape}
                        handle_shape_point_drag_end={
                            this.props.handle_shape_point_drag_end
                        }
                    />
                );

                if (
                    item['geojson'] &&
                    item['geojson']['geometry'] &&
                    item['geojson']['geometry']['coordinates'] &&
                    item['geojson']['geometry']['coordinates'].length > 0
                ) {
                    shapes_of_interest.push(poi);
                } else {
                    points_of_interest.push(poi);
                }

                //if (points_of_interest.length >= 500) {
                //    break;
                //}
            }
        }

        if (this.state.location.image) {
            let title = (
                <h1
                    style={{
                        position: 'absolute',
                        top: '-60px',
                        left: '100px',
                        cursor: 'pointer',
                        fontSize: '2rem',
                        zIndex: '110',
                        margin: '10px',
                        fontWeight: '400',
                    }}
                >
                    <a
                        onClick={(event) =>
                            this.select_poi(event, this.state.location)
                        }
                    >
                        {`${this.state.location.name} Map`}
                        <i
                            class="bi bi-info-circle-fill"
                            style={{
                                fontSize: '1.4rem',
                                color: '#bbb',
                                verticalAlign: 'middle',
                                padding: '10px',
                            }}
                        ></i>
                    </a>{' '}
                </h1>
            );

            if (isMobile) {
                title = null;
            }

            let map_maker_shout_out = (
                <CreatorCredit location={this.state.location} />
            );

            let sidebar = null;
            if (this.state.selected_point_of_interest) {
                let params = get_global_state()['params'];
                sidebar = (
                    <MapSidebar>
                        <PointOfInterestDetails
                            select_poi={this.select_poi}
                            location_has_position={
                                this.state.selected_point_of_interest
                            }
                            page_location={this.state.location}
                            campaign={campaign}
                            campaign_location_settings={
                                campaign_location_settings
                            }
                            campaign_notes={campaign_notes}
                            dm={dm}
                        />
                    </MapSidebar>
                );
            }

            let seo_details = (
                <Helmet>
                    <title>
                        Interactive {this.state.location.name} Map in DnD 5e
                    </title>
                    <meta
                        name="description"
                        content={
                            'A detailed and interactive map of' +
                            this.state.location.name +
                            ' for DnD 5e complete with images, other locations and wiki links.'
                        }
                    />
                    <meta
                        name="keywords"
                        content={
                            (this.state.location.name + ' map',
                            'map of ' + this.state.location.name)
                        }
                    />
                    <meta name="author" content="Forgotten Maps" />
                    <meta
                        property="og:title"
                        content={
                            'Interactive {this.state.location.name} Map in DnD 5e'
                        }
                    />
                    <meta
                        property="og:description"
                        content={
                            'A detailed and interactive map of ' +
                            this.state.location.name +
                            'for DnD 5e complete with images, other locations and wiki links.'
                        }
                    />

                    <meta
                        property="og:image"
                        content={this.state.location.seo_image}
                    />
                </Helmet>
            );

            let children_loading = (
                <LocationsLoading
                    children_loaded={this.state.children_loaded}
                    count={this.state.location.children.length}
                    total={this.state.location.total_children}
                />
            );

            return (
                <div>
                    {seo_details}

                    <Loading loaded={this.state.loaded}>
                        <div className="wooden-table"></div>
                        <CanvasMap
                            width={this.state.width}
                            height={this.state.height}
                            location={this.state.location}
                            selected_point_of_interest={
                                this.props.selected_point_of_interest ||
                                this.state.selected_point_of_interest
                            }
                            pan_location={this.state.pan_location}
                            handle_map_image_load={this.handle_map_image_load}
                            handle_zoom={
                                this.props.handle_zoom ||
                                ((zoom, position) =>
                                    this.setState({
                                        zoom: zoom,
                                        position: position,
                                    }))
                            }
                            handle_map_click={
                                this.props.handle_map_click ||
                                this.handle_map_click
                            }
                            handle_drag_end={(position) =>
                                this.setState({ position: position })
                            }
                            // Optional event listeners passthrough
                            handle_scroll={this.props.handle_scroll}
                            handle_mouse_down={this.props.handle_mouse_down}
                            handle_mouse_up={this.props.handle_mouse_up}
                            handle_key_down={this.props.handle_key_down}
                            handle_key_up={this.props.handle_key_up}
                        >
                            {shapes_of_interest}
                            {points_of_interest}
                        </CanvasMap>
                        {sidebar}
                        {map_maker_shout_out}
                        {title}
                        {children_loading}
                        <Search
                            locations={this.state.location.children}
                            pan_to_location={this.pan_to_location}
                        />
                    </Loading>
                </div>
            );
        } else {
            return (
                <Loading loaded={this.state.loaded}>
                    <Container>
                        <p>
                            We don't currently have a map for{' '}
                            {this.state.location.name} yet. Sorry!
                        </p>
                    </Container>
                </Loading>
            );
        }
    }
}
