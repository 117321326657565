import React, { Component } from 'react';

import { Loading, Form, FileInput, TextInput, Alert } from 'library';
import { ajax_wrapper, get_global_state, is_right_click } from 'functions';
import { MapSidebar, PointOfInterestEditor, EditShapeStatus } from 'components';
import { MapViewer } from 'pages';

const EXAMPLE_GEOJSON = {
    type: 'Feature',
    geometry: {
        type: 'LineString',
        coordinates: [],
    },
    properties: {},
};

const NUMBER_FIELDS = [
    'lat',
    'lng',
    'label_x_offset',
    'label_y_offset',
    'label_wrap_ratio',
];

const PAGE_SIZE = 200;

export default class MapEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            location: {
                children: [],
                campaign: {},
            },
            next_page: null,
            selected_point_of_interest: null,
            move_pin: false,
            editing_shape: false,
            shift: false,
            ctrl: false,
        };

        this.get_location = this.get_location.bind(this);
        this.handle_point_of_interest_click =
            this.handle_point_of_interest_click.bind(this);

        this.update_location = this.update_location.bind(this);
        this.toggle_editing_shape = this.toggle_editing_shape.bind(this);
        this.toggle_shape_type = this.toggle_shape_type.bind(this);
        this.update_shape = this.update_shape.bind(this);
        this.handle_map_click = this.handle_map_click.bind(this);

        this.move_position = this.move_position.bind(this);
        this.de_activate = this.de_activate.bind(this);
        this.update_text_callback = this.update_text_callback.bind(this);
        this.handle_selected_poi_update =
            this.handle_selected_poi_update.bind(this);

        this.handle_key_down = this.handle_key_down.bind(this);
        this.handle_key_up = this.handle_key_up.bind(this);
        this.handle_scroll = this.handle_scroll.bind(this);
        this.handle_point_of_interest_drag_end =
            this.handle_point_of_interest_drag_end.bind(this);
        this.handle_text_label_drag_end =
            this.handle_text_label_drag_end.bind(this);
        this.handle_point_of_interest_scroll =
            this.handle_point_of_interest_scroll.bind(this);
        this.handle_shape_point_drag_end =
            this.handle_shape_point_drag_end.bind(this);
    }

    componentDidMount() {
        // Get location
        this.get_location();
    }

    get_location() {
        let params = get_global_state()['params'];

        let url = '/api/location_map_details/sword_coast/';
        if ('name' in params) {
            url = `/api/location_map_details/${params['name']}/`;
        }
        ajax_wrapper('GET', url, {}, (value) => {
            if (!value['campaign']) {
                value['campaign'] = {};
            }
            if (!value['children']) {
                value['children'] = [];
            }
            this.setState({ location: value, loaded: true }, this.get_children);
        });
    }

    get_children = (page) => {
        let params = get_global_state()['params'];

        let url = '/api/location_children/sword_coast/';
        if ('name' in params) {
            url = `/api/location_children/${params['name']}/`;
        }

        if (page) {
            url += `?page=${page}`;
        }

        ajax_wrapper('GET', url, {}, (value) => this.load_children(value));
    };

    load_children = (value) => {
        let new_state = {
            location: this.state.location,
            next_page: value['next_page'],
        };

        if (!new_state['next_page']) {
            new_state['children_loaded'] = true;
        }

        // Prevent duplicated requests from extending too much
        if (
            (value['next_page'] &&
                new_state['location']['children'].length >
                    value['next_page'] * PAGE_SIZE) ||
            (!value['next_page'] &&
                new_state['location']['children'].length ==
                    value['children'].length)
        ) {
            return false;
        }

        for (let child of value['children']) {
            if (!child.wiki_page_content) {
                continue;
            }
            for (let section of child.wiki_page_content) {
                let section_text = '';
                for (let subsection of section.subsections) {
                    for (let item of subsection.content) {
                        section_text += item;
                    }
                }

                let clean_text = section_text.replaceAll(/<br>/g, '\n');
                clean_text = clean_text.replaceAll(/<br\/>/g, '\n');
                clean_text = clean_text.replaceAll(/<[^>]+(>|$)/g, '');
                clean_text = clean_text.replaceAll(/\[[^\]]+(\]|$)/g, '');
                clean_text = clean_text.replaceAll(/\t/g, '');
                clean_text = clean_text.replaceAll(/(\n)\1{1,}/g, '\n');
                clean_text = clean_text.replaceAll(/\n/g, '<br>');

                section['clean_text'] = clean_text;
            }
        }

        new_state['location']['children'].push(...value['children']);

        this.setState(new_state, () => {
            if (this.state.next_page) {
                this.get_children(this.state.next_page);
            }
        });
    };

    handle_point_of_interest_click(event, data) {
        if (this.state.selected_point_of_interest && this.state.editing_shape) {
        } else {
            event.cancelBubble = true;
            this.setState({ selected_point_of_interest: data });
        }
    }

    update_location(data) {
        let url = '/api/update_location/';
        ajax_wrapper(
            'POST',
            url,
            {
                image: data['image'],
                location_id: this.state.location.id,
                map_maker_name: data['map_maker_name'],
                map_maker_url: data['map_maker_url'],
            },
            console.log,
        );
    }

    handle_map_click(event, position) {
        if (is_right_click(event)) {
            return false;
        }
        if (!this.state.selected_point_of_interest) {
            return false;
        }

        if (is_right_click)
            if (this.state.move_pin) {
                let location = this.state.location;
                let children = [];
                for (let child of location.children) {
                    if (child.id === this.state.selected_point_of_interest.id) {
                        child['lat'] = position[0];
                        child['lng'] = position[1];
                        children.push(child);
                    } else {
                        children.push(child);
                    }
                    location['children'] = children;
                    this.setState({
                        location: location,
                        move_pin: false,
                    });
                }
                ajax_wrapper(
                    'POST',
                    '/api/change_position/',
                    {
                        id: this.state.selected_point_of_interest.id,
                        position: position,
                    },
                    () => this.setState({ selected_point_of_interest: null }),
                );
            } else if (this.state.editing_shape) {
                this.update_shape(position);
            } else {
                this.setState({ selected_point_of_interest: null });
            }
    }

    update_shape(position) {
        let adjusted_position = [
            position[0] - this.state.selected_point_of_interest['lat'],
            position[1] - this.state.selected_point_of_interest['lng'],
        ];
        this.state.selected_point_of_interest['geojson']['geometry'][
            'coordinates'
        ].push(...adjusted_position);

        this.setState({
            selected_point_of_interest: this.state.selected_point_of_interest,
        });
    }

    de_activate() {
        if (this.state.selected_point_of_interest) {
            let location = this.state.location;
            let children = [];
            for (let child of location.children) {
                if (child.id !== this.state.selected_point_of_interest.id) {
                    children.push(child);
                }
                location['children'] = children;
                this.setState({
                    location: location,
                    selected_point_of_interest: null,
                });
            }
        }
    }

    move_position() {
        this.setState({ move_pin: true });
    }

    toggle_editing_shape() {
        if (this.state.editing_shape) {
            this.setState({
                editing_shape: false,
            });
        } else {
            // Check and create geojson structure
            if (!this.state.selected_point_of_interest['geojson']['type']) {
                this.state.selected_point_of_interest['geojson'] =
                    Object.assign(
                        {},
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'LineString',
                                coordinates: [],
                            },
                            properties: {},
                        },
                    );
            }

            this.setState({
                editing_shape: true,
                selected_point_of_interest:
                    this.state.selected_point_of_interest,
            });
        }
    }

    toggle_shape_type() {
        if (
            this.state.selected_point_of_interest &&
            this.state.selected_point_of_interest['geojson']['geometry']
        ) {
            let current_type =
                this.state.selected_point_of_interest['geojson']['geometry'][
                    'type'
                ];
            if (current_type) {
                this.state.selected_point_of_interest['geojson']['geometry'][
                    'type'
                ] =
                    current_type == 'LineString'
                        ? 'MultiPolygon'
                        : 'LineString';
            }

            this.setState({
                selected_point_of_interest:
                    this.state.selected_point_of_interest,
            });
        }
    }

    update_text_callback(data) {
        if (this.state.selected_point_of_interest) {
            let location = this.state.location;
            for (let child of location.children) {
                if (child.id === data.id) {
                    child = Object.assign(child, data);
                }
                this.setState({
                    location: location,
                });
            }
        }
    }

    handle_key_down(event) {
        // Only events relevant to POIs
        if (!this.state.selected_point_of_interest) {
            return true;
        }

        if (event.key === 'Shift') {
            this.setState({ shift: true });
            return false;
        } else if (event.key === 'Control') {
            this.setState({ ctrl: true });
            return false;
        } else if (
            event.key === 'z' &&
            this.state.editing_shape &&
            this.state.ctrl
        ) {
            this.state.selected_point_of_interest['geojson']['geometry'][
                'coordinates'
            ].splice(-2, 2);

            this.setState({
                selected_point_of_interest:
                    this.state.selected_point_of_interest,
            });
            return false;
        } else if (event.key == 'a') {
            this.handle_selected_poi_update('hotkey', {
                rotation:
                    this.state.selected_point_of_interest['rotation'] - 10,
            });
        } else if (event.key == 'd') {
            this.handle_selected_poi_update('hotkey', {
                rotation:
                    this.state.selected_point_of_interest['rotation'] + 10,
            });
        } else if (event.key == 'w') {
            this.handle_selected_poi_update('hotkey', {
                scale: this.state.selected_point_of_interest['scale'] + 2,
            });
        } else if (event.key == 's') {
            this.handle_selected_poi_update('hotkey', {
                scale:
                    this.state.selected_point_of_interest['scale'] >= 6
                        ? this.state.selected_point_of_interest['scale'] - 2
                        : 4,
            });
        }

        return true;
    }

    handle_key_up(event) {
        if (event.key === 'Shift') {
            this.setState({ shift: false });
        } else if (event.key === 'Control') {
            this.setState({ ctrl: false });
        }
    }

    handle_scroll(event) {
        // Only events relevant to POIs

        var delta = event.deltaY;

        return true;
    }

    handle_selected_poi_update(name, data) {
        for (let key in data) {
            this.state.selected_point_of_interest[key] = data[key];
            if (NUMBER_FIELDS.includes(key)) {
                if (isNaN(data[key])) {
                    this.state.selected_point_of_interest[key] = 0;
                } else {
                    this.state.selected_point_of_interest[key] = parseFloat(
                        data[key],
                    );
                }
            }
        }
        this.setState({
            selected_point_of_interest: this.state.selected_point_of_interest,
        });
    }

    handle_point_of_interest_drag_end(event, position) {
        this.handle_selected_poi_update('dragend', {
            lat: position[0],
            lng: position[1],
        });

        return false;
    }

    handle_text_label_drag_end(event, position) {
        this.handle_selected_poi_update('dragend', {
            label_x_offset: position[0],
            label_y_offset: position[1],
        });
    }

    handle_point_of_interest_scroll(event) {
        event.cancelBubble = true;

        console.log('POI scroll');

        return false;
    }

    handle_shape_point_drag_end(event, index) {
        if (!this.state.selected_point_of_interest) {
            return true;
        }
        if (!this.state.editing_shape) {
            return true;
        }

        let coordinates =
            this.state.selected_point_of_interest['geojson']['geometry'][
                'coordinates'
            ];

        event.cancelBubble = true;
        coordinates[index] = event.target['attrs']['x'];
        coordinates[index + 1] = event.target['attrs']['y'];

        this.setState({
            selected_point_of_interest: this.state.selected_point_of_interest,
        });
    }

    render() {
        let add_map_form = null;
        let sidebar = null;

        if (this.state.location.image) {
            if (this.state.selected_point_of_interest) {
                sidebar = (
                    <MapSidebar>
                        <PointOfInterestEditor
                            location_has_position={
                                this.state.selected_point_of_interest
                            }
                            handle_selected_poi_update={
                                this.handle_selected_poi_update
                            }
                            editing_shape={this.state.editing_shape}
                            move_position={this.move_position}
                            toggle_editing_shape={this.toggle_editing_shape}
                            toggle_shape_type={this.toggle_shape_type}
                            de_activate={this.de_activate}
                            update_text_callback={this.update_text_callback}
                        />
                    </MapSidebar>
                );
            }
        } else {
            add_map_form = (
                <Form submit={this.update_location}>
                    <FileInput label="Map" name="image" />
                    <TextInput label="Map Maker Name" name="map_maker_name" />
                    <TextInput label="Map Maker Url" name="map_maker_url" />
                </Form>
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <MapViewer
                    location={this.state.location}
                    selected_point_of_interest={
                        this.state.selected_point_of_interest
                    }
                    shift={this.state.shift}
                    handle_map_click={this.handle_map_click}
                    handle_key_down={this.handle_key_down}
                    handle_key_up={this.handle_key_up}
                    handle_scroll={this.handle_scroll}
                    handle_point_of_interest_click={
                        this.handle_point_of_interest_click
                    }
                    handle_point_of_interest_drag_end={
                        this.handle_point_of_interest_drag_end
                    }
                    handle_text_label_drag_end={this.handle_text_label_drag_end}
                    handle_point_of_interest_scroll={
                        this.handle_point_of_interest_scroll
                    }
                    editing_shape={this.state.editing_shape}
                    handle_shape_point_drag_end={
                        this.handle_shape_point_drag_end
                    }
                />
                {add_map_form}
                {sidebar}

                <div
                    style={{
                        position: 'absolute',
                        bottom: '0px',
                        width: '100%',
                    }}
                >
                    <Alert
                        type={this.state.shift ? 'danger' : 'info'}
                        style={{
                            margin: '0px auto',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            width: 'fit-content',
                            padding: '10px 20px',
                        }}
                    >
                        {`Edit Mode  :  Shift ${this.state.shift} : Ctrl ${this.state.ctrl}`}
                        <EditShapeStatus
                            editing_shape={this.state.editing_shape}
                            data={this.state.selected_point_of_interest}
                        />
                    </Alert>
                </div>
            </Loading>
        );
    }
}
